/**
 * External Dependencies
 */
import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";

/**
 * Internal Dependencies
 */
import Portal from "@/components/portal/portal";
import { RingLoader as Loader } from "react-spinners";

/**
 * @typedef LoaderContextType
 * @type {object}
 * @prop {boolean} loading
 * @prop {React.Dispatch<React.SetStateAction<boolean>} setLoading
 */

/**
 * @type {React.Context<LoaderContextType>} LoaderContext
 */
export const LoaderContext = createContext({ loading: false });

export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const stopLoadingAfterNavigate = useCallback(() => {
        setLoading(false);
    }, [setLoading]);

    useEffect(() => {
        document.addEventListener("inertia:finish", stopLoadingAfterNavigate);

        return () => {
            document.removeEventListener(
                "inertia:finish",
                stopLoadingAfterNavigate,
            );
        };
    }, []);

    return (
        <LoaderContext.Provider
            value={{
                loading,
                setLoading,
            }}
        >
            {children}

            <Portal name="fullscreen-loader">
                {loading && (
                    <div className="z-50 fixed w-screen h-screen flex items-center justify-center bg-black/10">
                        <Loader size={100} color="#DD4B39" loading={loading} />
                    </div>
                )}
            </Portal>
        </LoaderContext.Provider>
    );
};

export default useLoader;
