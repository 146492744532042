/**
 * External Dependencies
 */
import "./bootstrap";

import React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/inertia-react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { FormProvider, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";

/**
 * Internal Dependencies
 */
import { LoaderProvider } from "@/utils/use-loader";

const AppWrapper = ({ children }) => {
    const form = useForm();

    return (
        <FormProvider {...form}>
            <LoaderProvider>{children}</LoaderProvider>
        </FormProvider>
    );
};

createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(`./${name}.jsx`, import.meta.glob("./**/*.jsx")),
    setup({ el, App, props }) {
        createRoot(el).render(
            <AppWrapper>
                <App {...props} />
            </AppWrapper>,
        );
    },
});
