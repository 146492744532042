import { useMemo } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, root = null, name = "modal" }) => {
    const mountPoint = useMemo(() => {
        if (root) {
            return root;
        }

        let mount = document.querySelector(`div[data-name=${name}]`);

        if (!mount) {
            mount = document.createElement("div");
            mount.dataset.name = name;
            document.body.insertBefore(mount, document.body.firstChild);
        }

        return mount;
    }, [root, name]);

    return createPortal(children, mountPoint);
};

export default Portal;
